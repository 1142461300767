// const http = "http://101.43.161.179:portkisssoul"  //本地
// const wss = "ws://101.43.161.179:portkisssoul"  //本地
const http = "https://www.kisssoul.com:8989/kisssoul"  //线上
const wss = 'wss://www.kisssoul.com:8989/kisssoul'
const register = http + "/user/register"  // 注册

const uploadImg = http + "/file/uploadFile" //图片上传

const userName = http + "/user/findByUserName"  // 验证用户名是否重复
const login = http + "/user/login"  // 登录

const showUserInfo = http + "/user/showUserInfo" //获取用户资料
const modifyUser = http + "/user/modifyUser" //修改个人资料

const findOpposite = http + "/user/" //显示所有异性

const websocket = wss + "/websocket" //websocket
const createChat = http + "/chatRecord/createChat"  //创建对话列表
const getChatList = http + "/chatRecord/getChatList"  //加载会话列表
const chattingInfo = http + "/chatRecord/chattingInfo" //切换对话框
const removeChat = http + "/chatRecord/removeChat" //删除会话框
const showChatRecords = http + "/chatRecord/showChatRecords" //所有对话框聊天记录


const showHotUser = http + "/user/showHotUser" // 推荐用户

const addDynamic = http + "/dynamic/addDynamic" //发布动态
const showAllDynamics = http + "/dynamic/showAllDynamics" //获取所有人动态
const showUserDynamics = http + "/dynamic/showUserDynamics" //查询个人动态
const removeDynamic = http + "/dynamic/removeDynamic" //删除动态

const showAll = http + "/userPhotos/showAll" //所有照片
const addPhoto = http + "/userPhotos/addPhoto" //上传照片
const removePhoto = http + "/userPhotos/removePhoto" //删除照片

const addVisitor = http + "/userVisitor/addVisitor" //创建访客
const userVisitor = http + "/userVisitor/showAll" //获取我的访客列表
const clearNoRead = http + "/userVisitor/clearNoRead" //清除未读状态

const userLikeShowAll = http + "/userLike/showAll" //查询可操的列表
const addMyLike = http + "/userLike/addMyLike" //添加操的
const showMyLike = http +  "/userLike/showMyLike" //查询操我的
const showLikeMe = http +  "/userLike/showLikeMe" //查询我操的
const findFriend = http + "/userLike/findFriend" //查询相互操的
const findUserLike = http + "/userLike/findUserLike" //查询是否被我操过

const giftShowAll = http + "/gift/showAll" //所有礼物列表
const showSendGifts = http + "/userGift/showSendGifts" //我送出去的礼物
const showReceiveGifts = http + "/userGift/showReceiveGifts" //我回到的礼物
const addUserGift = http + "/userGift/addUserGift" //发送礼物

const commitOrder = http + "/order/commitOrder" //支付
const getIpAndGuid = http + "/order/getIpAndGuid" //支付ip
const findNoRead = http + '/userGift/findNoRead' //查询未读礼物

const closeChat = http + "/chatRecord/closeChat"

const showChatRecordsMore = http + "/chatRecord/showChatRecordsMore"
export default {
    http,
    register,
    uploadImg,
    userName,
    login,
    showUserInfo,
    modifyUser,
    findOpposite,
    websocket,
    createChat,
    getChatList,
    chattingInfo,
    removeChat,
    showChatRecords,
    showHotUser,
    addDynamic,
    showAllDynamics,
    showUserDynamics,
    removeDynamic,
    showAll,
    addPhoto,
    removePhoto,
    addVisitor,
    userVisitor,
    clearNoRead,

    userLikeShowAll,
    addMyLike,
    showMyLike,
    showLikeMe,
    findFriend,
    findUserLike,
    giftShowAll,
    showSendGifts,
    showReceiveGifts,
    addUserGift,
    commitOrder,
    getIpAndGuid,
    findNoRead,
    closeChat,
    showChatRecordsMore
}