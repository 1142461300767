import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/index.css"
import "./assets/css/iconfont.css"
import VueI18n from 'vue-i18n'

import LangENUS from './assets/language/en-us.js'
import LangZHCN from './assets/language/zh-cn.js'
import LangZHTW from './assets/language/zh-tw.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import VueQuillEditor from 'vue-quill-editor'
Vue.use(VueQuillEditor)

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import isAxios from './assets/js/axios.js'
Vue.prototype.$isAxios = isAxios

import isApi from './assets/js/api.js'
Vue.prototype.$isApi = isApi

import isPulic from './assets/js/pulic.js'
Vue.prototype.$isPulic = isPulic

import isChat from './assets/js/chatList.js'
Vue.prototype.$isChat = isChat

import VueCropper from 'vue-cropper'
Vue.use(VueCropper)

import JwChat from 'jwchat';
Vue.use(JwChat)

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: sessionStorage.lang,
  messages: {
    'en-us': LangENUS,
    'zh-cn': LangZHCN,
    'zh-tw': LangZHTW
  },
  silentTranslationWarn: true
})

Vue.config.productionTip = false

String.prototype.IsPicture = function(){
  var strFilter=".jpeg|.gif|.jpg|.png|.bmp|.pic|"
  if(this.indexOf(".")>-1){
    var p = this.lastIndexOf(".")
    var strPostfix=this.substring(p,this.length) + '|'   
    strPostfix = strPostfix.toLowerCase()
    if(strFilter.indexOf(strPostfix)>-1){
      return true
    }
  }
  return false
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')