module.exports = {
    locale: 'zh-cn',
    login: {
        yidenglu: "账号已登录，请勿重复登录",
        lianjiechenggong: "链接成功",
        chongxinlianjie: "重新链接中……",
        diaoxian: "您已斷线，正在重新链接",
        addUserChat: "创建了跟您的新对话",
        deleteUserChat: "終止了跟您的對話",
        allMore: "没有更多",
        more: "更多",
        renzheng: "请您联系客服审核认证！",
        qian_01: "价格",
        qian_02: "描述",
        qian_03: "留言",
        qian_04: "返回",
        vipState_01: "您暂时不是",
        vipState_02: "无法发布动态，请点击右下悬浮按钮进行",
        shenVip: "不能打开对话框！",
        shenVipState: "您不是VIP会员，无法发布动态，是否跳转升级?",
        shenVipState1: "您不是SVIP会员，无法查看视频动态，是否跳转升级?",
        shenVipState2: "您不是VIP会员，是否跳转升级?",
        giftZhenMsg: "赠送成功，届时客服人员会与该用户联络。",
        giftZhen: "真实礼物",
		giftXu: "虚拟礼物",
        title: '欢迎加入Kisssoul',
        username: "请输入账号",
        nickName: "请输入昵称",
        password: "请输入密码",
        pwd: "请再次输入",
        email: "请输入邮箱地址",
        logingo: "登录",
        preserve: "记住账号",
        register: "没有账号？点击注册",
        login: "已有账号？点击登录",
        confirm: "点击注册",
        welcome: "欢迎您",
        information: "完善资料",
        gogo: "直接进入",
		noPassword: "您输入的账号密码不正确"
    },
    verification: {
        veriOne: "请您正确输入",
        veriTwo: "您输入的格式不正确",
        veriThree: "两次输入不一致",
        veriFour: "您的用户名或密码不正确",
        veriFive: "此号码已被注册"
    },
    nav: {
        home: "首页",
        online: "在线交友",
        act: "动态",
        upgrade: "升级",
        note: "邮件"
    },
    home: {
        text: "请输入",
        recommend: "热门推荐",
        address: "所在地",
        welcomeBack: "欢迎回来",
        balance: "余额",
        class: "会员等级",
        personalCenter: "进入个人中心",
        loginOut: "安全退出",
    },
    class: {
        vip1: "普通用户",
        vip2: "VIP",
        vip3: "SVIP",
        vip4: "钻石会员",
        vip5: "红钻贵族",
        text: "会员到期时间"
    },
    grilList: {
        search: "我要找",
        sexOne: "男",
        sexTwo: "女",
        searchSelect: "请选择",
        searchSet: "请设置",
        searchNickName: "请输入用户昵称",
        nowSearch: "搜索",
        removeSearch: "清除搜索",
        btnChat: "聊天",
        btnLove: "喜欢",
        btnGift: "送礼",
        onLine: "在线",
        offLine: "离线",
        noText: "暂无",
        noContent: "暂无签名",
        addGril: "您已添加过喜欢",
        addSuccess: "添加成功"
    },
    love: {
        name: "邂逅游戏",
        noLove: "不喜欢",
        noText: "暂无数据"
    },
    newState: {
        name: "最新动态",
        more: "更多"
    },
    age: {
        name: "岁 "
    },
    sendUser: {
        title_1: "这人很懒，没有发布任何相片",
		title: "基本资料",
        myContent: "我的详情",
        perfect: "完善资料",
        userName: "账号",
        realName: "真实姓名",
        cardID: "证件号码",
        nickName: "昵称",
        marriageStatus: "现状",
        line: "联系方式",
        city: "常驻地",
        height: "身高",
        weight: "体重",
        age: "年龄",
        education: "学历",
        signature: "个性签名",
        income: "月收入",
        noText: "未设置",
        loveSay: "爱情宣言",
		text_01: "基本信息",
		title_01: "尊敬的用户，实名认证在更好保障您权益的同时也能让您获得更高的关注度，实名认证信息必须真实，负责将承担相应法律责任。",
		title_02: "尊敬的用户，完善资料和详情能让您获得更高的关注度，个人资料和相关内容要遵守站点法规，请勿弄虚作假。"
    },
    userLeft: {
        title: "头像能让您关注度更高哦！",
        cardReal: "实名认证",
		cardRealGo: "已实名认证",
		cardRealNo: "未实名认证",
        time: "注册时间",
        success: "确定",
        realName: "请输入真实姓名",
        width: "长度在2到4个字符",
        cardID: "请输入证件号码",
        yesCardID: "证件号码错误",
        myHome: "我的首页",
        myContent: "我的详情",
        myState: "我的动态",
        myPhoto: "我的相片",
        myCaller: "我的访客",
        myGetGifts: "我收的礼物",
        mySetGifts: "我送的礼物",
        myLive: "邂逅游戏",
        myPassword: "修改密码",
        successfully: "操作成功",
        noSuccessfully: "操作失败"
    },
    userTop: {
        title: "完善实名资料，提高曝光率。",
        text: "请确保您资料的真实性，您的详细资料会通过管理员验证，通过后有机会成为推荐会员，博得",
        textGo: "更多关注",
        balance: "余额",
        already: "已有",
        visit: "访问过您"
    },
    giftComponents: {
        title: "虚拟礼物",
        giftGo: "您正在为",
        setGo: "赠送",
        content: "请输入送礼时您想和对方说的话",
        successGo: "确认赠送",
        notSufficientFunds: "您的账户余额不足，是否前往充值！",
        reminder: "提示",
        cancel: "取消",
        success: "赠送成功",
		userList: '用户列表'
    },
    pageBottom: {
        aboutUs: "关于我们",
        clause: "使用条款",
        privacy: "隐私政策",
        safety: "交友安全",
        contactUs: "帮助中心",
        center: "联系我们",
        refund: "退款声明"
    },
    pageFixed: {
        service: "客服",
        recharge: "充值",
        upgrade: "升级",
        noChat: "您当前没有聊天对象哦！"
    },
    pageHeader: {
        myHome: "我的主页",
        dataModification: "资料修改",
        password: "密码修改",
        out: "安全退出"
    },
    pay: {
        payGo: "充值",
        title: "请选择您要充值的金额：",
        stitle: "请选择您要充值的方式：",
        go: "下一步",
        sstitle: "请完善以下内容",
        immediatelyPay: "立即充值",
        goBack: "返回上一层",
        call: "电话",
        mailbox: "邮箱",
        custom: "自定义",
        noCall: "电话号码不能为空",
        noNumber: "请输入数字值",
        noCallge: "电话号码格式不正确",
        noMail: "邮箱不能为空",
        noMailge: "邮箱格式不正确",
        noName: "请输入您的真实姓名",
        noNameNumber: "长度在 2 到 32 个字符",
		title_01: "请完善以下信息"
    },
    say: {
        content: "资料",
        setGift: "送礼物"
    },
    passWordUser: {
        name: "密码修改",
        odd: "旧密码",
        oddtext: "请输入旧密码",
        new: "新密码",
        newtext: "请输入新密码",
        newBe: "请再输入",
        newBetext: "请再次输入新密码",
        cuss: "确认修改",
        addPassWordNo: "旧密码有误",
        twoPassWordNo: "再次密码不一致",
        textNO: "输入有误",
		title: "我的礼物"
    },
    userCaller: {
        title: "我的访客",
        time: "来访时间",
        text: "签名"
    },
    userDetails: {
		cityForm: "省市区",
        title: "我的资料/修改",
        sex: "性别",
        header: "头像",
        userDate: "出生日期",
        select: "选择",
        address: "地址",
        addressText: "详细地址",
        marriage: "婚姻状况",
        marriageOne: "未婚",
        marriageTwo: "已婚",
        marriageThree: "丧偶",
        marriageFour: "离异",
        marriageFive: "不透漏",
        occupation: "职业",
        occupation_01: "学生",
        occupation_02: "教师",
        occupation_03: "董事长",
        occupation_04: "总经理",
        occupation_05: "副总/总监",
        occupation_06: "中层管理",
        occupation_07: "企业家",
        occupation_08: "中层管理/经理",
        occupation_09: "个体老板",
        occupation_10: "干部",
        occupation_11: "公务员",
        occupation_12: "律师",
        occupation_13: "医护人员",
        occupation_14: "工程师",
        occupation_15: "演员",
        occupation_16: "模特",
        occupation_17: "技术人员",
        occupation_18: "服务人员",
        occupation_19: "公益人员",
        occupation_20: "专家",
        occupation_21: "学者",
        occupation_22: "兼职",
        occupation_23: "销售",
        occupation_24: "微商",
        occupation_25: "人力资源/HR",
        occupation_26: "多媒体",
        occupation_27: "军人",
        occupation_28: "自由职业",
        education_01: "小学",
        education_02: "初中",
        education_03: "高中",
        education_04: "大专",
        education_05: "本科",
        education_06: "硕士",
        education_07: "博士",
        headerCai: "头像裁剪",
        size: "上传文件大小不能超过 5MB!"
    },
    userGetGifts: {
        title: "收到的礼物",
        text_01: "在",
        text_02: "向您赠送了",
        text_03: "并留言",
        text_04: "您没有任何送礼记录！"
    },
    userLive: {
        title_01: "我喜欢的",
        title_02: "喜欢我的",
        title_03: "相互喜欢的"
    },
    myPhoto: {
        title: "我的相册",
        updata: "上传相片",
        text: "照片支持格式为JPG，PNG，照片大小不超过1000k，上传照片后，严禁上传色情，暴力，恐怖，反动等违法照片！",
        click: "点击上传",
        myPhoto: "我的相片",
        notCvailable: "暂无相片",
        text_01: "请先上传照片",
        deletePhoto: "此操作将永久删除该相片, 是否继续?",
        deleteSuccess: "删除成功",
		title_01: "尊敬的用户，相册丰富可以让您的关注度更高。相册相关内容要遵守站点法规，请勿弄虚作假。"
    },
    userSetGifts: {
        title: "送出的礼物",
        text_01: "您在",
        text_02: "向",
        text_03: "送了",
        text_04: "并留言",
		text_05: "在"
    },
    myState: {
        title_text: "点击发布",
        title: "我的动态",
        text_01: "发布一个动态，提高曝光率",
        imgState: "图片动态",
        videoState: "视频动态",
        text_02: "请输入内容",
        text_03: "只允许上传mp4格式文件！",
        text_04: "立即发布",
        delete: "删除",
        text_05: "您暂未发布任何动态！",
        text_06: "恭喜你，发布成功！",
        text_07: "发布动态必须配有文字。",
        text_08: '此操作将永久删除这条动态, 是否继续?',
		title_01: "尊敬的用户，丰富的动态可以让您的关注度更高。动态相关内容要遵守站点法规，请勿弄虚作假。",
		title_02: "重新选择"
    },
    userXQ: {
        text_01: "打招呼",
        text_02: "送礼物",
        text_03: "喜欢",
        text_04: "这人很懒，没有发布任何动态！",
        title_01: "他/她的相册",
        title_02: "他/她的动态",
    },
    // content为文案，全版复制老版网站，请自行修改。
    content: {
        title_ccccc: "适用法律-这些服务条款以及我们向您提供服务的任何单独协议均应受香港法律管辖并解释。",
        title_01: "公司简介",
        text_01: "Kisssoul致力於在230多個國家和地區開拓網上交友服務產業，並管理多種不同語言的網站。在這裡您可以盡情的和朋友分享趣事和喜悅，為您提供一個全世界最快捷、最好玩的交友平台，每天都有成千上百新會員加入。 ",
        title_02: "我们的使命是什么",
        text_02: "我們的使命很簡單：幫助大家認識來自全世界的朋友。我們認為：這方面我們做得相當不錯。每個月我們都收到全世界各地的來信，與我們分享他們與在Kisssoul上認識的朋友的故事。",
        title_03: "怎样操作",
        text_03: "我們為用戶提供認識及了解全世界朋友的機會，通過不同的自由編寫版塊，讓他們在其檔案中詳細介紹他們自己。可以上傳多張圖片，以及有關他們的興趣愛好。我們知道隱私保護的重要性，所以，會員間所有的聯繫都是通過匿名郵件網絡進行的。我們對所有會員的姓名和聯繫信息都保密，除非會員自己想與對方分享個人信息的情況除外，一旦註冊完成，會員可以立即瀏覽潛在朋友的照片與日誌，並與其聯繫。",
        text_04: "Kisssoul訂立了有關網站服務（以下簡稱服務）的下述協議（“使用條款”）。無論誰使用我們的服務，都被視為已同意遵守我們的使用條款",
        title_04: "适用法律-这些服务条款以及我们向您提供服务的任何单独协议均应受香港法律管辖并解释。",
        title_05: "1. 同意使用條款",
        text_05: "本協議（使用條款）包括現在和未來所經營的網站及客戶端。通過使用此服務，無論您是否註冊成為Kisssoul的會員（以下簡稱為會員），您將被視為已經同意這些使用條款。如果您不同意此服務條款，請您停止使用我們的服務。 ",
        title_06: "2. 合格要求",
        text_06: "年齡未滿18歲的未成年人不得使用我們的服務。通過使用我們的服務，您已聲明並保證您有權利和能力遵守使用條款的所有規定。",
        title_07: "3. 使用條款的變更",
        text_07: "Kisssoul有權在無需事先通知的情況下，變更本使用條款。您可以定期查看服務條款的變更情況。當我們對使用條款進行變更後，如果您使用我們的服務，無論您瀏覽與否，即視為您同意這些變更。",
        title_08: "4. 隱私",
        text_08: "Kisssoul尊重網站訪問者的隱私權。服務的使用應遵循我們的隱私權政策。 Kisssoul的合作夥伴同意遵循隱私權政策的規定。",
        title_09: "5. 服務變更",
        text_09: "Kisssoul可以自行決定增加，刪除或修改服務功能或各項服務，而無需事先通知。",
        title_10: "6.訂製",
        text_10_1: "a) 支付方式",
        text_10_2: "您的支付條款將基於您的支付方式上，支付條款由您和金融機構，信用卡發行機構或其他您選擇的支付服務提供商（支付服務提供商）之間的協定來決定。如果Kisssoul不接受您的“支付服務提供商”所提供的付款服務，您同意根據需要支付所有金額。您同意為使用我們的服務，支付所有的應付賬款。如果您的賬款已付，您可以請求我們取消您的已付賬款的狀態，一旦您的賬戶被取消，你的已付費狀態將在您最後支付的日期時終止。已付費狀態不能轉讓。 Kisssoul的已付費狀態是最終銷售，不能退款。有爭議的費用應在您購買服務後的60天內上報給Kisssoul。如對於您的已付費狀態有何疑問，請聯繫我們。",
        text_10_3: "b) 服務續約",
        text_10_4: "您在Kisssoul網站訂製的服務將不會自動續約。您可以聯繫我們的客服團隊，隨時變更或重新訂製。如果您選擇重新訂製，您的訂製服務在到期後將不能自動續約。在訂製期滿前取消訂製的，不能得到退款。",
        text_10_5: "c) 免費試用和其他促銷",
        text_10_6: "任何免費試用或其他促銷方式，可使訂製者在特定的試用期限使用我們的服務。如果促銷條款規定使用者需要訂製服務，您應該在試用期之前取消訂製，以避免交付訂製費",
        title_11: "7. 通過合作夥伴加入Kisssoul",
        text_11: "如果您通過Kisssoul的合作夥伴註冊使用我們的服務，並且Kisssoul與其合作夥伴進行合作，提供該服務。你將有使用該服務的會員權利，並且Kisssoul有權轉移您的會員資格或將您的已付款賬戶轉移到Kisssoul的類似服務項目內。",
        title_12: "8. 社區內容",
        text_12: "所有的服務內容都應該遵循社區內容指南的要求。您同意不發布，傳送，分發或鏈接任何涉及違法，騷擾，毀謗，威脅，有害，猥褻，悖逆，誹謗，詆毀他人名譽，以及侵犯別人隱私的內容，也不發送其他寧人不悅或侵害其他人或團體權益的內容。未經知識產權人的書面同意，不得使用他人的知識產權，包括受版權和商標權保護的資料，變更或未變更的知識產權，其他人的非版權文本或圖片等。 Kisssoul將不為會員所發的任何郵件或群討論帖的內容負責，也不對第三方或會員提供的任何信息，商品或服務負責。使用我們的服務，即表明您同意：在以下情況下，Kisssoul將不對您或其他人負責：例如：任何方的威脅，誹謗，猥褻，下流，攻擊或違法行為​​，或侵權行為，包括違法使用知識產權。 Kisssoul 不擁有您提供給所有信息（包括回饋和建議），或您在Kisssoul所發布，下載，輸入或提交的所有信息（“提交的信息”）。然而，如果您在我們的網站或客戶端上發布，上傳，輸入，提供或提交信息，即表明您給予Kisssoul永久不可撤銷並且可轉讓的權利，Kisssoul有權複製，使用，儲存，修改，編輯，翻譯或發布任何或全部您所提交的信息，並且無需支付信息的使用費。 Kisssoul沒有義務發布或使用您所提交的信息，並且Kisssoul可以隨時自行決定刪除您所提交的信息。你在本網站發布，下載，輸入或提交信息，即表明您擁有您所發布信息的控制權。對於您的信息被刪除，無效或儲存失敗，Kisssoul和其合作方將不為此負責。",
        title_13: "9. 會員賬戶",
        text_13: "一旦您註冊了我們的服務，您將會收到一個賬號和密碼，您應對您的賬戶和密碼保密，並對您賬戶相關的所有活動負責。一旦註冊成為會員，您將為您檔案的所有信息負責。禁止在檔案或網站服務項目中發布色情或其他淫穢的內容，因為這樣會威脅您的會員身份。對於Kisssoul網站的會員進行的線下約會，Kisssoul將不負任何責任。您同意Kisssoul向您發送郵件（涉及服務信息（包括變更升級），新功能或事件，使用服務的意見和建議）。",
        title_14: "10. 受版權保護和商標權保護的材料",
        text_14: "Kisssoul網站的內容均受版權保護Copyright© 2024。",
        title_15: "11. 免責聲明",
        text_15: "在Kisssoul網站或通過Kisssoul（或Kisssoul的代理商或合作方）發布的資料，均按“現有”和“現存”提供。它並無各種明示或暗示的保證和條件。 Kisssoul不控制第三方所提供的任何信息，產品或服務。您明確同意，使用服務將獨自承擔風險。根據適用法例的最大容許程度：Kisssoul就所有明確或隱含保證做出免責聲明，而此等保證範圍包括但不限於隱含的可銷售性及特殊用途合適性保證；Kisssoul並不保證所提供的服務能滿足您的需求，也不保證服務將不中斷，適時，安全或正確無誤。 Kisssoul不為服務使用和使用的結果做擔保。 Kisssoul也不保證本網站或服務項目不含任何病毒，或其他有害組件。 Kisssoul不保證或聲明：保持網站傳送信息的機密性。 Kisssoul不保證網站翻譯的準確和完整。 Kisssoul不為以下做擔保：通過本網站購買或獲得的任何商品或服務，或在本網站做廣告或贊助的商品或服務，或任何通過本網站所進行的交易。 Kisssoul不對您從Kisssoul或其服務中所獲得的信息做任何明示的保證（無論口頭還是書面）。",
        text_16_c: "Kisssoul尊重用戶的隱私權，並製定隱私政策，保護您的隱私權。隱私政策包括：Kisssoul的用戶情況，我們所收集的信息，怎樣利用這些信息，以及與誰分享這些信息。當您使用我們的網站或服務，或與我們進行商務交易時，我們建議你仔細閱讀“隱私政策”。通過使用我們的網站，表明您已接受本保密協議的規定。 Kisssoul非常重視網絡隱私。如果您對該隱私政策有任何疑問，請聯繫我們。 ",
        title_16: "未滿18歲的人士",
        text_16: "Kisssoul不會故意收集或保存未滿18歲人士的個人驗證信息或非個人驗證信息，本網站下的任何項目均不面向未滿18歲的人士開放。如果你未滿18歲，請停止使用或禁止進入本網站或服務。如果Kisssoul獲悉所收集的信息中有未滿18歲人士的個人驗證信息，本網站將採取合理的步驟，刪除該信息。",
        title_17: "們所收集的信息",
        text_17: "您填寫的相關信息（包括您的個人信息，聯繫信息，以及當您創建會員檔案時所填寫的其他檔案信息）；當你自由填寫顧客調查表，提供反饋和參與競爭時，所提供的相關信息;如果您與我們聯繫，我們會記錄你的通信信息;交易信息包括您從我們網站定購有關產品和服務的信息;瀏覽我們網站的訪客，並且他們的信息已被記錄的，可以有權以相同的信息登錄，並有權更正信息或查看有關該信息的政策和規則。訪客可以直接與我們聯繫。",
        title_18: "其他方收集的信息",
        text_18: "我們允許第三方（包括我們的授權服務提供商，廣告公司和廣告網站）在我們的網站上發表廣告。如果你打開一個鏈接（例如一個廣告標誌），這將使你將離開我們的網站，登錄其他的網站，如果你隨後在其他網站上提供信息，我們將不能控制該信息的使用。本隱私政策將不對其他網站或廣告商的信息保密負責。對於其他網站如何使用你的信息，Kisssoul也將不為此負擔任何責任。",
        title_19: "我們怎樣使用收集的信息？",
        text_19_1: "管理您的用戶賬戶（包括：通知您任何匹配的對象，提示，通告，更新資料或促銷活動",
        text_19_2: "幫助你訂製個性化的網站回訪",
        text_19_3: "向您提供您所詢問的信息，產品或服務，或者你感興趣的信息；進行市場分析和調查",
        text_19_4: "幫助我們改進服務結構，佈局和適用性",
        title_20: "我們與誰分享所收集的信息",
        text_20: "我們可能在特定方面將您的信息與第三方分享，例如：當您從我們網站訂製服務時，我們提供你的信息給第三方進行付款處理。但是我們將控制第三方使用您的信息，並對此負責。我們有可能將您的某些信息透露給我們的合作公司，以便向您提供服務，例如：郵件或聊天功能服務。為了提供這些服務，我們必須將您的信息（包括您的Kisssoul用戶名和email地址）提供給我們的合作夥伴。",
        title_21: "Cookies",
        text_21: "我們竭力按照您的喜好為您提供服務。我們使用Cookies 保存並記錄有關於您的信息。 Cookie是指通過網絡服務器發送到您瀏覽器的一小部分數據，該數據儲存在您的計算機硬盤上。",
        title_22: "變更隱私政策",
        text_22: "我們經常審核“隱私政策”，並在此頁面公佈政策的變更信息。我們鼓勵會員經常審核我們的隱私政策，以便更好地了解我們所收集的信息，以及我們怎樣運用信息，以及在何種情況下使用信息，與他人分享信息。",
        title_23: "怎樣聯繫我們",
        text_23: "如果您對於本隱私政策或對於我們的信息處理方式有何疑問，或對於我們透露信息給第三方（為了直銷的目的）有疑問，請通過email或信件與聯繫我們。郵箱：Kisssoul2@outlook.com",
        text_23_1: "在Kisssoul ，我們非常嚴肅地保護會員的隱私。我們始終一貫地管理網站上所有的個人主頁、照片和所使用的語言，並儘一切所能保護你的隱私和安全。然而，我們要提醒你：網上安全也是你的責任，這非常重要。所以要小心，三思而後行。保護自己的安全！",
        text_23_2: "不要向任何第三方- 即使是您的朋友- 分享或透露您的密碼。如果您丟失或洩露您的密碼，可能會危及您的私人數據。如果您的個人檔案被黑客入侵，請您通過我們的反饋頁面向我們團隊進行舉報。",
        text_23_3: "當您對新認識的人還不太了解時，請您不要輕易洩露個人信息。這包括透露您的電子郵件地址，即時通信的聯繫方式，全名及URL。請您千萬不要告訴他人您的財務信息，如信用卡資料等。",
        text_23_4: "有些騙子會假冒我們公司或其他公司的代表要求您為非Kisssoul網站直接提供的所謂獎品的快遞或服務付款，因此請您一定要特別警惕。尤其請您要注意的是，當有人說他們有困難而向您要錢時- 這種情況通常是騙局。",
        text_23_5: "如果您遇到任何不恰當的行為，點擊舉報違規行為的鏈接後，我們團隊會馬上收到該報告。如果您與某位會員交流時覺得不自在，您也能找到阻止該會員的選項。不要忍受任何無禮或騷擾行為- 阻止該會員或向我們的客戶服務團隊舉報。我們會盡快處理這些事宜，我們擁有一個完善的監管系統對這方面進行監控和質量控制。",
        text_23_6: "當您與剛認識的新會員交談時，問他們要最近拍的照片- 最好是在過去4個星期內拍的",
        text_23_7: "千萬不要迫使自己去與對方見面；只有當您準備好的時候才可以去見面",
        text_23_8: "把您打算去的地方告訴您的朋友，並且讓他們給您打電話或發短信以確認您的安全。請您確保帶上電量充滿並且餘額足夠的手機，並且及時把見面的情況和何時安全到家告知你的朋友。",
        text_23_9: "請您自己安排前往和離開見面地點的交通工具- 不要在對方或自己的家裡見面。最好永遠不要洩露您的住址，除非您與對方已經非常熟悉。",
        text_23_10: "請您確保將第一次見面安排在白天以及公共場所，並且在您清醒時！請您千萬不要在見面時喝太多酒，並且時時將自己的隨身物品和飲品放在自己的眼前。",
        title_21: "關於線下見面的提示",


        title_24: "1. 我無法登陸網站",
        text_24: "請首先確認您是否成功註冊Kisssoul賬號，然後確認您的賬號密碼輸入是否正確，如果仍然無法登陸，請點擊“忘記密碼”，如仍然無法登陸，請及時聯繫網站管理員。",
        title_25: "2. 忘記密碼",
        text_25: "如果你忘記您的登陸密碼，當你登陸的時候，請點擊“忘記密碼”選項，進行密碼找回或是重置密碼。",
        title_26: "3. 如何升級會員",
        text_26: "您可以點擊升級會員圖標按鈕，然後選擇你所要進行升級的會員等級進行升級。",
        title_27: "4. 如何更好的使用翻譯功能",
        text_27: "Kisssoul網站是一個針對全球的SNS國際交友網站，在這里通過Kisssoul翻譯平台，讓全球朋友溝通無限！當你擁有較高的會員權限，你將可以享有更多的翻譯權限。",
        title_28: "5. 如何舉報非法賬號",
        text_28: "當您發現一些非法賬號，可以進入他的個人主頁，點擊“舉報”。或是及時聯繫我們網站管理員，我們將會作出調查並進行處理，以確保網站的安全性。",
        title_29: "6. 如何進行支付",
        text_29: "您可以登錄Kisssoul點擊充值，我們目前僅提供PayPal、支付寶等網絡安全支付方式，所有的支付方式都是絕對安全的。本網站絕沒有任何自動記憶或重複收費的程序。有任何疑問，請及時聯繫網站管理員提出您的問題。",
        title_30: "7.充值不成功",
        text_30: "如果您在Kisssoul的充值頁面無法成功充值，請先查看你的信用卡是否有問題，然後更換瀏覽器重新嘗試。有時您也可以選擇聯繫貝寶（比如在系統提示\"您的信用卡不能用於支付這筆交易\"的情況下）。如果各種方法都嘗試過後仍舊無法成功充值，請將詳細情況告訴我們，比如您使用的是哪種充值方式，有何錯誤提示？使用哪種瀏覽器？為了協助我們技術人員快速查找原因幫你解決問題，請您盡可能將出錯頁面截圖發給我們網站管理員。",
        text_31_1: "Company: Jingkun Tech Limited",
        text_31_2: "Address: 71-75 Shelton Street, Covent Garden, London, United Kingdom",
        text_31_3: "E-mail: Kisssoul2@outlook.com",
        text_31_4: "在這裡您可以找到一些我們會員經常遇到問題的答案，如果您有很棒的想法、或發現了錯誤、有問題要諮詢，請發送郵件到我們的郵箱，我們會盡快的幫您解決。如需要幫助的用戶較多時，不能迅速答复，請耐心等待。工作時間為星期一至星期日09：00～18：00 。為了能更為大家提供更好的服務，其他問題請大家查找幫助中心或者使用郵件和我們聯繫。",
        title_32: "退款声明",    
        text_32: "充值消费错误请24小时联系客服邮箱Kisssoul2@outlook.com提供相关证明，联系售后工作人员办理。购买时间超过48小时，不予以办理退款，其他正常享受售后服务，详细咨询售后客服。申请售后服务前，请您务必发送邮件Kisssoul2@outlook.com或登录网站会员联系在线客服人员。",
    },
    upgrade: {
        title: "升级",
        send: "为自己升级",
        user: "为好友升级",
        userName: "请输入好友用账户",
        userSearch: "搜索",
        text_01: "尽情聊天",
        text_02: "節省",
        text_03: "月",
        text_04: "照片权限",
        text_05: "查看谁喜欢你",
        text_06: "认识更多异性",
        text_07: "让你陪受瞩目",
        text_08: "更多展示机会",
		title_01: "请选择升级方式"
    }
}