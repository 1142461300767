<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'Home',
  data(){
    return{

    }
  },
  created(){
    if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.location.href = "https://www.kisssoul.com/"
      // window.location.href = "http://101.43.161.179/"
      // window.location.href = "http://localhost/"
    }
  },
  methods: {
    
  }
}
</script>

