export default {
    // 获取当前时间
    crtime(call) {
        var myDate = new Date()
        var year = myDate.getYear()
        var FullYear = myDate.getFullYear()
        var Month = myDate.getMonth() + 1
        var Datec = myDate.getDate()
        var Day = myDate.getDay()
        var Time = myDate.getTime()
        var Hours = myDate.getHours()
        var Minutes = myDate.getMinutes()
        var Seconds = myDate.getSeconds()
        var DateString = myDate.toLocaleDateString()
        var mytime = myDate.toLocaleTimeString()
        var toLocale = myDate.toLocaleString()
        var json = {
            year: year,
            FullYear: FullYear,
            Month: Month,
            Datec: Datec,
            Day: Day,
            Time: Time,
            Hours: Hours,
            Minutes: Minutes,
            Seconds: Seconds,
            DateString: DateString,
            mytime: mytime,
            toLocale: toLocale
        }
        var nowDate = FullYear + "/" + this.getZero(Month) + "/" + this.getZero(Datec) + " " + this.getZero(Hours) + ":" + this.getZero(Minutes) + ":" + this.getZero(Seconds)
        var NowTime = " " + Hours + ":" + Minutes + ":" + Seconds
        call(json, nowDate, NowTime)
    },
    // 根据出生年月日算年龄
    age_Conversion(str) {
        var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
        if (r == null) return false;
        var d = new Date(r[1], r[3] - 1, r[4])
        if (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d.getDate() == r[4]) {
            var Y = new Date().getFullYear()
            return ((Y - r[1]))
        }
        return ("输入的日期格式错误！")
    },
    // 聊天用户去重
    formateArrObjData(initialArr, obj, pro){
        // 判定数据是否为数组
        if (!(initialArr instanceof Array)) {
          return '请传入正确格式的数组'
        }
        // 判定数据是否为对象
        if (!(obj instanceof Object)) {
          return '请传入正确格式的对象'
        }
        if (!pro) {
          return '请传入正确格式的属性名'
        }
        let index = initialArr.findIndex((val) => val[pro] === obj[pro])
        // 如果有就替换  没有就添加
        if (initialArr.findIndex((val) => val[pro] === obj[pro]) !== -1) {
            initialArr.splice(index, 1, obj);
        } else {
            initialArr.push(obj);
        }
        return initialArr
    },
    // 数组转，隔开
    acTiveArrStringFun: function(obj) {
        var arr = []
        if (obj != null && obj.length != 0) {
            for (var i = 0; i < obj.length; i++) {
                arr.push(obj[i])
            }
        }
        return arr.toString()
    },
    // 时间戳转日期
    timestampToTime(timestamp) {
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes()
        var s = date.getSeconds()
        return Y + M + this.getZero(D) + this.getZero(h) + this.getZero(m)
    },
    // 时间戳转日期
    timestampToTimeccc(timestamp) {
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate()
        var h = date.getHours()
        var m = date.getMinutes()
        var s = date.getSeconds()
        return Y + M + this.getZero(D)
    },
    // 单数前面加0
    getZero(num){  
        if(parseInt(num) < 10){  
          num = '0'+num;
        }  
        return num;  
    },
    endWith(endStr){
        var d = this.length - endStr.length;
        return (d>=0&&this.lastIndexOf(endStr)==d)
    }
}