import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    socketYes: false,
    vipYes: false,
    userList: [],
    userListNumber: 0,
    sayShow: false,
    chatForm: {},
    chatArrForm: [],
    setContent: {},
    getContent: {},
    sayNumber: 0,
    userVisitor: [],
    noReadCount: 0,
    giftNumber: 0,
    sousuo: {},
    loveGift: [],
    giftList: [],
    giftShow: false,
    setGiftUserForm: {},
    payShow: false,
    upgradeShow: false
  },
  mutations: {
    // 接收数据
    getSocke(state,getContent){
      state.getContent = getContent
    },
    // 发送数据
    setSocke(state,setContent){
      state.setContent = setContent
    }
  },
  actions: {
  },
  modules: {
  }
})
