import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 解决路由跳转多次点击报错的问题
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

// 路由详情
const routes = [{
  path: '/',
  name: 'login',
  component: () => import( '../views/Login.vue')
},{
  path: '/loginContent',
  name: 'loginContent',
  component: () => import( '../views/loginContent.vue')
},{
  path: '/register',
  name: 'register',
  component: () => import( '../views/Register.vue')
},{
  path: '/page/home',
  name: 'page',
  component: () => import( '../views/Index.vue'),
  children: [{
    path: '/page/home',
    name: 'home',
    component: () => import( '../views/page/Home.vue')
  },{
    path: '/page/content',
    name: 'content',
    component: () => import( '../views/content.vue')
  },{
    path: '/page/information',
    name: 'information',
    component: () => import( '../views/page/Information.vue')
  },{
    path: '/page/love',
    name: 'love',
    component: () => import( '../views/page/Love.vue')
  },{
    path: '/page/loveWall',
    name: 'loveWall',
    component: () => import( '../views/page/LoveWall.vue')
  },{
    path: '/page/userXQ',
    name: 'userXQ',
    component: () => import( '../views/page/UserXQ.vue')
  },{
    path: '/page/user/information',
    name: 'user',
    component: () => import( '../views/page/User.vue'),
    children: [{
      path: '/page/user/information',
      name: 'userInformation',
      component: () => import( '../views/page/user/userInformation.vue'),
    },{
      path: '/page/user/getGifts',
      name: 'userGetGifts',
      component: () => import( '../views/page/user/userGetGifts.vue'),
    },{
      path: '/page/user/live',
      name: 'userLive',
      component: () => import( '../views/page/user/userLive.vue'),
    },{
      path: '/page/user/setGifts',
      name: 'userSetGifts',
      component: () => import( '../views/page/user/userSetGifts.vue'),
    },{
      path: '/page/user/state',
      name: 'userState',
      component: () => import( '../views/page/user/userState.vue'),
    },{
      path: '/page/user/details',
      name: 'userDetails',
      component: () => import( '../views/page/user/userDetails.vue'),
    },{
      path: '/page/user/photo',
      name: 'photo',
      component: () => import( '../views/page/user/userPhoto.vue'),
    },{
      path: '/page/user/caller',
      name: 'caller',
      component: () => import( '../views/page/user/userCaller.vue'),
    },{
      path: '/page/user/password',
      name: 'password',
      component: () => import( '../views/page/user/password.vue'),
    }]
  }]
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  var token = sessionStorage.getItem('token')
  if(token){
    next()
  }else{
    if (to.path === '/' || to.path === '/register' || to.path === '/loginContent') {
      next()
    } else {
      return next({ "path": "/" })
    }
  }
})
router.afterEach((to,from,next)=>{
  window,scrollTo(0,0)
})
export default router
