import isAxios from './axios'
import isApi from "./api.js"
import isStore from '../../store/index.js'
export default {
    // 获取当前聊天列表人员和所有人员聊天记录
    chatList(sendForm,call){
        isAxios.axiosGet(isApi.showChatRecords,{userId:sendForm.userId},(chatArrForm)=>{
            if(chatArrForm.data){
                isStore.state.sayNumber = chatArrForm.data.totalNoReadCounts
                isStore.state.chatArrForm = chatArrForm.data.result
                call(chatArrForm.data)
                console.log('chat user list')
            }
        })
    },
    // 删除某人对话
    chatfilter(e){
        return isStore.state.chatArrForm.filter(item => item.chatListId !== e)
    },
    // 创建会话
    newChat(e,item){
        var sendForm = JSON.parse(sessionStorage['sendForm'])
        isAxios.axiosPost(isApi.createChat,{sendId:sendForm.userId,receiveId:item.userId},(res)=>{
            if(res.data.result == 'alreadyExists'){
                this.chatList(sendForm,(chat)=>{
                    var ccc = chat.result.find((v) => {
                        return v.receiveUser.userId == item.userId
                    })
                    ccc.noReadCount = 0
                    isStore.state.chatForm = ccc.receiveUser
                    isStore.state.sayShow = true
                    // chat.result.forEach( say => {
                    //     say.noReadCount = 0
                    //     if(say.receiveUser.userId == item.userId){
                    //         isStore.state.chatForm = say.receiveUser
                    //         isStore.state.sayShow = true
                    //         return false
                    //     }
                    // })
                })
            }else{
                this.chatList(sendForm,(chat)=>{
                    var ccc = chat.result.find((v) => {
                        return v.receiveUser.userId == item.userId
                    })
                    isStore.state.chatForm = ccc.receiveUser
                    isStore.state.sayShow = true
                    // chat.result.forEach( say => {
                    //     if(say.receiveUser.userId == item.userId){
                    //         isStore.state.chatForm = say.receiveUser
                    //         isStore.state.sayShow = true
                    //         return false
                    //     }
                    // })
                })
            }
        })
    }
}