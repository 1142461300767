import axios from 'axios'
import api from "./api.js"
import { Message } from 'element-ui'
export default {
    // 登录
    axiosLogin: (url, json, call) => {
        var instance = axios.create({
            baseURL: api.http,
        })
        instance.post(url, json)
        .then((response) => {
            call(response)
        })
        .catch((error) => {
            Message({
                message: 'exception login',
                type: 'warning'
            })
            console.log(error)
        })
    },
    // 注册
    axiosRegister: (url, json, call) => {
        var instance = axios.create({
            baseURL: api.http,
        })
        instance.post(url, json)
        .then((response) => {
            call(response)
        })
        .catch((error) => {
            Message({
                message: 'exception register',
                type: 'warning'
            })
            console.log(error)
        })
    },
    // 增
    axiosPost: (url, json, call) => {
        var instance = axios.create({
            baseURL: api.http,
        })
        instance.defaults.headers.common['Authorization'] = sessionStorage.token
        instance.post(url, json)
        .then((response) => {
            call(response)
        })
        .catch((error) => {
            Message({
                message: 'exception post',
                type: 'warning'
            })
            sessionStorage.setItem("token", "")
            console.log(error)
        })
    },
    //查
    axiosGet: (url, json, call) => {
        var instance = axios.create({
            baseURL: api.http,
        })
        instance.defaults.headers.common['Authorization'] = sessionStorage.token
        instance.get(url, { params: json })
        .then((response) => {
            call(response)
        })
        .catch((error) => {
            Message({
                message: 'exception get',
                type: 'warning'
            })
            sessionStorage.setItem("token", "")
            console.log(error)
        })
    },
    // 改
    axiosPut: (url, json, call) => {
        var instance = axios.create({
            baseURL: api.http,
        })
        instance.defaults.headers.common['Authorization'] = sessionStorage.token
        instance.put(url, json)
        .then((response) => {
            call(response)
        })
        .catch((error) => {
            Message({
                message: 'exception put',
                type: 'warning'
            })
            sessionStorage.setItem("token", "")
            console.log(error)
        })
    },
    // 删
    axiosDelete: (url, json, call) => {
        var instance = axios.create({
            baseURL: api.http,
        })
        instance.defaults.headers.common['Authorization'] = sessionStorage.token
        instance.delete(url, { params: json })
        .then((response) => {
            call(response)
        })
        .catch((error) => {
            Message({
                message: 'exception request',
                type: 'warning'
            })
            sessionStorage.setItem("token", "")
        })
    }
}